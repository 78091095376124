import "../stylesheets/application"

import "@hotwired/turbo-rails"
import "../controllers"

import "channels"

import "@coreui/coreui"
import "@fortawesome/fontawesome-free/js/all"

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "./add_jquery"

require("@nathanvda/cocoon")
import * as bootstrap from "bootstrap"

import "@client-side-validations/client-side-validations"
import "@client-side-validations/simple-form/dist/simple-form.bootstrap4"
import "mapkick/bundle"

window.bootstrap = bootstrap

import 'toastr/build/toastr.min.js'
import 'toastr/build/toastr.min.css'

import toastr from "toastr/toastr"
toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": true,
  "progressBar": true,
  "positionClass": "toastr-top-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "3000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}
window.toastr = toastr

window.Turbo

Rails.start()
ActiveStorage.start()

document.addEventListener('DOMContentLoaded', () => {
  coreui.initAll();
});

