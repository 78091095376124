import jquery from "jquery"
import 'datatables.net-bs4'
import 'datatables.net-buttons'
import 'datatables.net-buttons-bs4'

window.$ = jquery
window.jQuery = jquery
window.jquery = jquery

// document.addEventListener("DOMContentLoaded", function() {
//   window.$ = jquery
//   window.jQuery = jquery
//   window.jquery = jquery
// })