import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table-result"
export default class extends Controller {
  static targets = ["resultDiv"]

  connect() {
    document.addEventListener("data-sent", this.handleDataSent.bind(this))
  }

  handleDataSent(event) {
    this.resultDivTarget.innerHTML = event.detail.html
    const modalElement = document.querySelector("#resultModal")
    const modal = new bootstrap.Modal(modalElement)
    modal.show()
  }

  closeModal() {
    const modalElement = document.querySelector("#resultModal")
    const modal = new bootstrap.Modal(modalElement)
    modal.hidden()
  }
}
