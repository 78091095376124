import { Controller } from "@hotwired/stimulus"

const objective   = 1
const variables   = 5
const constraints = 3
const animals     = 1
const rhsOffset = 17
const relationOffset = 16

export default class extends Controller {
  static targets = ["table"]

  connect() {
    this.loadTableFromCSV(";", "/kayseri.csv")

    // Manually set up click event for the button
    const prepareJsonButton = document.getElementById("prepare-json")
    if (prepareJsonButton) {
      prepareJsonButton.addEventListener("click", this.prepareJson.bind(this))
    }
  }

  async loadTableFromCSV(delimiter, file) {
    try {
      const response = await fetch(file);
      const csvData = await response.text();
      const rows = csvData.split("\n").map(row => row.split(delimiter));

      const table = document.createElement("table");
      table.setAttribute("id", "matrix-table");
      table.classList.add("table", "table-responsive-sm", "table-striped");

      const tbody = document.createElement("tbody");
      var realRowIndex = 0;

      rows.forEach((rowData, rowIndex) => {
        const row = document.createElement("tr");
        row.classList.add("text-right");
        var currentCol = 0;

        rowData.forEach((cellData, cellIndex) => {
          const cell = rowIndex === 0 || cellIndex === 0 ? document.createElement("th") : document.createElement("td"); // Use <th> for header cells
          cell.id = `cell-${rowIndex}-${currentCol}`;

          // Get the content of the corresponding header cell and the first cell in the row
          const headerCellContent = rows[0][cellIndex]; // Content of header cell
          const firstCellContent = rowData[0]; // Content of first cell in the row

          // Combine the content for the hint
          const hint = `${firstCellContent} <--> ${headerCellContent}`;
          cell.title = hint;

          cell.textContent = cellData;
          row.appendChild(cell);
          row.id = `row-${realRowIndex}`;

          if (rowIndex !== 0 && cellIndex !== 0) {
            cell.addEventListener("dblclick", this.dblClick.bind(this));
          }

          currentCol++;
        });
  
        tbody.appendChild(row);
        realRowIndex++;
      });

      table.appendChild(tbody);
      const tableWrapper = document.querySelector("#rawData");
      tableWrapper.appendChild(table);
      this.element.appendChild(tableWrapper);
    } catch (error) {
      console.error("Error loading table:", error);
    }
  }

  async sendMotor(jsonData) {
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').content
      const response = await fetch("/reports/send_motor", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: jsonData,
      })
      if (!response.ok) {
        throw new Error('Network response was not ok.')
      }
      const stringData = await response.json()
      const data = JSON.parse(stringData.data)
      const html = this.generateTableHTML(data)
      
      this.sendData(html)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  sendData(html) {
    const customEvent = new CustomEvent("data-sent", { detail: { html } })
    document.dispatchEvent(customEvent)
  }

  generateTableHTML(data) {
    let html = '<div class="card text-dark bg-light">'
    html += '<div class="card-header">'
    html += '<b>Analiz</b>'
    html += '</div>'
    html += '<div class="card-body">'
    html += "<table id='result-table-1' class='table table-sm table-striped'>"
    // Header row
    html += "<thead><tr class='text-right'>"
    html += "<th>Üretim Faaliyeti</th>"
    html += "<th>Değer</th>"
    html += "<th>Azalan Gelir/Artan Masraf</th>"
    html += "<th>Alt Sınır</th>"
    html += "<th>Üst Sınır</th>"
    html += "</tr></thead>"

    // Body
    html += "<tbody>"
    data.response.sensitivity.coeff.forEach(coeff => {
      html += "<tr class='text-right'>"
      html += `<td>${coeff.activity}</td>`
      html += `<td>${(new Intl.NumberFormat("de-DE").format(coeff.dual_value))}</td>`
      html += `<td>${(new Intl.NumberFormat("de-DE").format(coeff.obj_coef))}</td>`
      html += `<td>${(new Intl.NumberFormat("de-DE").format(coeff.range_from))}</td>`
      html += `<td>${coeff.range_till}</td>`
      html += "</tr>"
    })
    html += "</tbody>"
    html += "</table>"
    html += "</div>"
    html += "</div>"

    // bound
    html += '<div class="card text-dark bg-light">'
    html += '<div class="card-header">'
    html += '<b>Analiz</b>'
    html += '</div>'
    html += '<div class="card-body">'
    html += "<table id='result-table-2' class='table table-sm table-striped'>"

    html += "<thead><tr class='text-right'>"
    html += "<th>Üretim Faaliyeti</th>"
    html += "<th>Kullanılan Kaynak</th>"
    html += "<th>Gölge Fiyat</th>"
    html += "<th>Artan Kaynak (Slack)</th>"
    html += "<th>Alt Sınır</th>"
    html += "<th>Üst Sınır</th>"
    html += "</tr></thead>"
    html += "<tbody>"
    data.response.sensitivity.bound.forEach(bound => {
      var slack = bound.dual_value - bound.range_upper
      html += "<tr class='text-right'>"
      html += `<td>${bound.activity}</td>`
      html += `<td>${(new Intl.NumberFormat("de-DE").format(bound.dual_value))}</td>`
      html += `<td>${(new Intl.NumberFormat("de-DE").format(bound.lower_bound))}</td>`
      html += `<td>${(new Intl.NumberFormat("de-DE").format(slack))}</td>`
      html += `<td>${(new Intl.NumberFormat("de-DE").format(bound.range_upper))}</td>`
      html += `<td>${(new Intl.NumberFormat("de-DE").format(bound.range_lower))}</td>`
      html += "</tr>"
    })
    html += "</tbody>"
    html += "</table>"
    html += "</div>"
    html += "</div>"

    return html
  }

  click(event) {
    event.preventDefault()
  }

  dblClick(event) {
    event.preventDefault() // Add this line to prevent the default behavior of the browser

    const cell      = event.currentTarget
    const rowIndex  = cell.parentNode.rowIndex
    const colIndex  = cell.cellIndex

    var mTable = document.getElementById("matrix-table")
    var mCellX = mTable.rows[rowIndex].cells[0]
    var mCellY = mTable.rows[0].cells[colIndex]
    var message = `${mCellX.innerHTML} --> ${mCellY.innerHTML}\n`

    if (rowIndex == undefined || colIndex == undefined) { return }

    if (rowIndex !== 0 || colIndex !== 0) {
      const oldValue = cell.textContent.trim()
      const newValue = prompt(`${message}\nYeni içeriği girin:`, oldValue)

      if (newValue !== null) {
        cell.textContent = newValue
      }
    }
  }

  prepareJson() {
    var variables = []
    var variableCount = 0

    var relation = ""
    var rhs = 0

    var objectives = []
    var constraints = []
    var coefficients = []

    var table = document.getElementById("matrix-table")
    var tbody = table.getElementsByTagName("tbody")[0]
    var rows  = tbody.getElementsByTagName("tr")
    var currentRow = 0

    // for row
    for (var row = 0; row < rows.length; row++) {
      var cells = rows[row].getElementsByTagName("td")
      if (cells.length === 0) { continue }

      if ((currentRow != row && row != 0) || (currentRow != 0 && row != 0) || (currentRow != 1 && row != 0)) {
        var obje = {}
        obje = {
          name: pname,
          rhs: rhs,
          relation: relation,
          coefficients: coefficients
        }

        coefficients = []
        constraints.push(obje)
        currentRow = row
      }

      // for cell
      relation = ""
      for (var cell = 0; cell < cells.length; cell++) {
        switch (row) {
          case 0:
            if (cell === 0) {
              break
            }
            else if (cell <= (relationOffset - 1)) {
              variables.push(cells[cell].textContent)
              variableCount++
            }

            break
          case 1:
            // objective function
            if (cell === 0) { break }
            if (cells[cell].textContent.trim()) {
              var value = parseFloat(cells[cell].textContent.replace(",", "."))
              objectives.push(value)
            }
            break
          default:
            switch (cell) {
              case 0:
                if (row > 0) {
                  var pname = cells[cell].textContent
                }
                break
              case relationOffset:
                relation = cells[cell].textContent
              case rhsOffset:
                rhs = parseFloat(cells[cell].textContent.replace(",", "."))
                break
              default:
                coefficients.push(parseFloat(cells[cell].textContent.replace(",", ".")))
            }
        }
      }
    }

    var rawConstraints = { constraints }
    rawConstraints.constraints.splice(0, 2)

    const data = {
      name: "Bafra",
      variables: variables,
      objective: {
        name: "Amaç Fonksiyonu",
        coefficients:  objectives
      },
      constraints: constraints
    }

    const jsonData = JSON.stringify(data, null, 2)
    this.sendMotor(jsonData)

    let date = new Date().toUTCString()
    console.log("Json motor'a gönderildi (%s).", date)
  }
}
