import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  connect() {
    const reportPlaceTypeSelectElement    = document.getElementById("initial_matrix_report_place_type");
    // const reportPlaceIdsSelectElement     = document.getElementById("initial_matrix_report_place_ids");
    const reportAssignmentsSelectElement  = document.getElementById("initial_matrix_assignment_id");

    const reportPlaceTypeSelect = new SlimSelect({
      select: reportPlaceTypeSelectElement,
      showSearch: true,
      events: {
        afterChange: () => {
          this.changeReportPlaceType();
        }
      }
    });

    const slimChangeHandler = () => {
      this.changeReportPlaceType();
    };

    reportPlaceTypeSelectElement.removeEventListener('change', slimChangeHandler);
    reportPlaceTypeSelectElement.addEventListener('change', slimChangeHandler);

    this.changeReportPlaceType = () => {
      var values = reportPlaceTypeSelect.getSelected();

      switch (values[0]) {
        case "province":
          this.updateReportPlaceIdsOptions(reportPlaceIdsSelect, "Province");
          break;
        case "district":
          this.updateReportPlaceIdsOptions(reportPlaceIdsSelect, "District");
          break;
        case "community_district":
          this.updateReportPlaceIdsOptions(reportPlaceIdsSelect, "CommunityDistrict");
          break;
        case "village":
          this.updateReportPlaceIdsOptions(reportPlaceIdsSelect, "Village");
          break;
      }
    };

    const reportAssignmentsSelect = new SlimSelect({
      select: reportAssignmentsSelectElement,
      settings: {
        allowDeselect: true,
        closeOnSelect: true,
        minSelected: 1,
        placeholderText: 'Görevlendirme Seçimi',
        searchPlaceholder: 'Görevlendirme Seçimi',
        searchText: 'Aradığınız kayıt bulunamadı :('
      }
    });
  }


  updateReportPlaceIdsOptions(reportPlaceIdsSelect, modelName) {
    fetch(`/reports/places?model=${modelName}`)
      .then((response) => response.json())
      .then((data) => {
        reportPlaceIdsSelect.setData(
          data.map((place) => ({ value: place[0], text: place[1] }))
        );
      })
      .catch((error) => console.error(error));
  }

  listAssignments(search) {
    fetch(`/reports/assignments?search=${search}`)
      .then((response) => response.json())
      .then((data) => {
        assignmentId.setData(
          data.map((place) => ({ value: place[0], text: place[1] }))
        );
      })
      .catch((error) => console.error(error));
  }
}
