import consumer from "./consumer"

consumer.subscriptions.create("NotificationChannel", {
  connected() {
    console.log("Connected to NotificationChannel");
  },

  disconnected() {
    console.log("Disconnected from NotificationChannel");
  },

  received(data) {
    console.log("Received notification:", data);
    this.showNotification(data.message, data.status);
  },

  showNotification(message, status) {
    console.log("Showing notification:", message, status);
    if (typeof toastr !== 'undefined') {
      toastr.options = {
        closeButton: false,
        debug: false,
        newestOnTop: true,
        progressBar: true,
        positionClass: "toast-top-right",
        preventDuplicates: false,
        onclick: null,
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 5000,
        extendedTimeOut: 1000,
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut"
      };      
      switch(status) {
        case 'success':
          toastr.success(message);
          break;
        case 'error':
          toastr.error(message);
          break;
        case 'warning':
          toastr.warning(message);
          break;
        default:
          toastr.info(message);
      }
    } else {
      console.error("Toastr is not defined");
      alert(message);
    }
  }
});
